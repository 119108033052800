<template>
  <div class="mt-5 iteam-area">
    <div class="text-left ml-10 mb-1">
      <v-label class="fs-14px text-theme-label font-weight-medium">
        {{ field.name }}
      </v-label>
    </div>
    <div
      :key="field.uid"
      class="item-area position-relative"
    >
      <icon-dots />
      <v-textarea
        v-if="field.dataType === 'text'"
        v-model="field.value"
        :placeholder="field.name"
        background-color="#F9F9FB"
        class="ml-5 pt-1 mt-0 rounded-lg field-theme"
        auto-grow
      />
    </div>
  </div>
</template>

<script>
import iconDots from '@/assets/svg/dots-20x20-gray.svg';

export default {
  components: {
    iconDots,
  },
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style>
.item-area {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.case-contents {
  display: block;
  max-width: 438px;
  width: 438px;
}
</style>
